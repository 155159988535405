<template>
  <div class="guildManagePage">
    <div class="headFlexBox">
      <div class="headBoxText">数据导入</div>
      <div>
        <el-button type="text" icon="icon iconfont icon-daoru" @click="handleImport">导入</el-button>
      </div>
    </div>
    <div class>
      <el-card class="box-card">
        <div>
          <tablePage
            :tableData="tableData"
            :currentPage="currentPage"
            :height="tableHeight"
            :isHeadColor="false"
            ref="tableList"
            :total="total"
            @current-change="currentChange"
            v-loading="loading"
          >
            <el-table-column prop="import_id" label="id"></el-table-column>
            <el-table-column prop="type" label="类型"></el-table-column>
            <el-table-column prop="start_date" label="开始日期"></el-table-column>
            <el-table-column prop="end_date" label="结束日期"></el-table-column>
            <el-table-column prop="description" label="备注"></el-table-column>
          </tablePage>
        </div>
      </el-card>
    </div>
    <el-dialog title="导入数据" :visible.sync="dialogImportForm" :show-close="false">
      <el-form :model="form" ref="form" label-position="right" label-width="170px" :rules="rules">
        <el-form-item label="日期区间：" prop="time" >
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="类型：" prop="type" :error="errorMessage?errorMessage.type:''">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Excel文件：" :error="errorMessage?errorMessage.file:''">
          <el-upload
            class="upload-demo"
            action
            accept=".xls, .xlxs, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :auto-upload="false"
            :on-change="handleChange"
            :on-success="fileSuess"
            :limit="1"
            :file-list="fileList"
          >
            <el-button class="cus_btn" icon="icon iconfont icon-shangchuanbiaoge">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="form.description"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="form_btn" type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tableHeight from "mixins/tableHeight";
import tablePage from "components/tablePage";
import api from "@/api/guildManageapi";
const options = [{ id: 1, value: "直播数据" }];
export default {
  name: "importdata",
  components: {
    tablePage,
  },
  mixins: [tableHeight],
  data() {
    var validateTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("日期不能为空"));
      } else {
        callback();
      }
    };
    var validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("类型不能为空"));
      } else {
        callback();
      }
    };
    return {
      errorMessage:null,
      loading: true,
      fileUrl: "",
      file: null,
      fileList: [],
      options,
      currentPage: 1,
      total: 0,
      isHeadColor: false,
      tableData: [],
      value1: "",
      dialogImportForm: false,
      form: {
        time: "",
        type: null,
        description: "",
      },
      rules: {
        time: [{ validator: validateTime, trigger: "change" }],
        type: [{ validator: validateType, trigger: "change" }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //进入页面加载表格内容
    async getList() {
      const data = await api.importDataApi({ page: this.currentPage });
      if (data.status_code === 200) {
        this.tableData = data.message;
        this.total = data.meta.pagination.total;
        this.loading = false;
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.loading = true;
      this.getList();
    },
    handleImport() {
      this.form.type = 1;
      this.removeForm()
      this.dialogImportForm = true;
    },
    //上传文件状态改变时的钩子
    handleChange(file, fileList) {
      // console.log(file)
      let fileName = file.name;
      let regex = /(.xls|.xlsx)$/;
      if (regex.test(fileName.toLowerCase())) {
        // 判断特殊字符
        if (fileName.lastIndexOf("+") == -1) {
          this.fileUrl = URL.createObjectURL(file.raw);
        } else {
          fileList = [];
          this.fileList = fileList;
          this.$message.error("文件名存在特殊字符，请修改文件名");
        }
      } else {
        fileList = [];
        this.fileList = fileList;
        this.file = null;
        this.$message.error("请选择Excel文件");
      }
      this.file = file.raw;
      console.log(this.file);
    },
    fileSuess(response, file) {
      console.log(file);
      file = null;
      this.file = file;
      this.fileUrl = "";
      this.file = null;
    },
    submitForm() {
      this.errorMessage = null
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.file){
            this.$throttle(this.importUpdata,10000)
          }else{
            this.$message.error("请选择文件");
          }
        } else {
          console.log("return");
          return false;
        }
      });
    },
    async importUpdata() {
      let formData = new FormData();
      formData.append("type", this.form.type);
      formData.append("file", this.file);
      formData.append("description", this.form.description);
      formData.append("start_date", this.form.time[0]);
      formData.append("end_date", this.form.time[1]);
      const { status_code, message } = await api.importUpdataApi(formData);
      if (status_code === 200) {
        this.removeForm()
        
        this.dialogImportForm = false;
        this.getList();
        this.$message({
          message: message,
          type: "success",
        });
        this.file = null;
        this.fileList = [];
      } else if (status_code === 422) {
        this.errorMessage = message
      }
    },
    removeForm() {
      this.$nextTick(() => {
        this.form = {
          time: "",
          type: 1,
          description: "",
        };
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetFields();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css/headCss.less";
.guildManagePage /deep/ .iconfont {
  margin-right: 4px;
}
.cus_btn {
  width: 110px;
  height: 32px;
  color: #333333;
}
.guildManagePage /deep/ .el-date-editor .el-range-separator {
  line-height: 29px;
}
// .guildManagePage /deep/ .el-table {
//   height: 600px;
// }
.guildManagePage /deep/ .cus_button {
  color: #666666;
}
.guildManagePage /deep/ .form_btn.el-button {
  width: 85px;
  height: 32px;
  border: none;
  font-size: 14px;
  border-radius: 2px;
}
.guildManagePage /deep/ .el-button--primary {
  background: linear-gradient(270deg, #fe61a2 0%, #f39064 100%);
  box-shadow: 0px 3px 6px 0px rgba(244, 81, 11, 0.3);
}
.guildManagePage /deep/ .el-dialog__body {
  padding: 0 20px 0 0;
}
.guildManagePage /deep/.el-form-item__label {
  padding: 0;
}
.guildManagePage /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.guildManagePage /deep/ .el-dialog__header {
  padding: 17px 20px 17px;
  margin-bottom: 10px;
  text-align: center;
}
.guildManagePage /deep/ .el-form-item {
  margin-bottom: 0px;
}
.guildManagePage /deep/ .el-form-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.guildManagePage /deep/ .el-form-item__label {
  line-height: 34px;
}
.guildManagePage /deep/ .el-form-item__content {
  line-height: 34px;
}
.guildManagePage /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
}
.guildManagePage /deep/ .el-dialog__footer {
  padding: 10px 20px 13px;
  margin-top: 70px;
  margin-bottom: 10px;
}
.guildManagePage /deep/ .el-input__icon {
  line-height: 26px;
}
.guildManagePage /deep/.el-input__inner {
  width: 230px;
}
.guildManagePage /deep/.el-textarea {
  width: 230px;
}
</style>